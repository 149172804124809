<template>
  <div class="checkout-steps" >
    <header-steps
      :steps="steps"
      :current-step="currentStep"
    />
    <main>
      <component
        :is="currentStep.key"
        :installment-options="installmentOptions"
        :name-plan="namePlan"
        @next-step="handleNextStep"
        @back-step="handleBackStep"
        @finished="(paymentMethod) => $emit('checkout-finished', paymentMethod)"
      />
    </main>
  </div>
</template>

<script>
import HeaderSteps from "./Header"
import BillingData from "./Main/BillingData"
import Payment from "./Main/Payment"
import Confirmation from "./Main/Confirmation"
import NumberAccesses from "./Main/NumberAccesses"
import validation from "./Main/Validation"
import AdhesionContract from "./Main/AdhesionContract.vue"

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CheckoutSteps',
  components: {
    HeaderSteps,
    BillingData,
    Payment,
    Confirmation,
    NumberAccesses,
    validation,
    AdhesionContract,
  },
  props: {
    installmentOptions: { type: Array, required: true },
    namePlan: { type: String, required: true },
    blockNext: { type: Boolean, required: true }
  },
  computed: {
    ...mapGetters('checkout2', {
      steps: 'getSteps',
      currentStep: 'getCurrentStep',
    })
  },
  methods: {
    ...mapActions('checkout2', {
      handleBackStep: 'handleBackStep',
      handleNextStep: 'handleNextStep',
    })
  },
}
</script>

<style lang="scss" scoped>
.checkout-steps {
  max-width: 950px;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 16px;

  background: var(--white);

  @media only screen and (max-width: 720px) {
    width: 100%;
  }

  &.block-next {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      width: 100%;
      height: 100%;
      background: #ffffff6c;
      cursor: not-allowed;
    }
  }

  main {
    width: 100%;
  }
}
</style>
