<template>
  <div class="payment">
    <!-- <div class="promotion" v-if="namePlan === 'AN1HO' && !getEmailIndication">
      <a @click="havePromotionCode = !havePromotionCode">Possui um código promocional? Clique aqui!</a>
      <div class="check-code" v-if="havePromotionCode">
        <base-input-text
          v-model="discountCoupon"
          type="text"
          placeholder="PROMO123"
          block
        />
        <base-button success @click="checkPromotion" style="height: 48px">
          Aplicar
        </base-button>
      </div>
    </div> -->

    <base-input-radio
      v-model="payment.method"
      :options="methodsOptions"
    />
    <p>
      <template v-if="payment.method == 'credit_card'">
        A liberação do acesso à plataforma HOFácil Web acontece de maneira automática a partir do retorno positivo da compensação financeira advinda da instituição financeira utilizada para realizar o pagamento.
        O pagamento via cartão de crédito pode levar até 5 minutos para ser analisado e compensado.
      </template>
      <template v-else-if="payment.method == 'bank_slip'" >
        <p>
          <strong>Quando paga via PIX:</strong> a liberação do acesso à plataforma HO Fácil Web acontece em até 15 minutos.
        </p>

        <p>
          <strong>Quando paga via boleto bancário:</strong> a liberação do acesso à plataforma HO Fácil Web acontece de maneira
          automática a partir do retorno positivo da compensação financeira advinda da instituição financeira utilizada para
          realizar o pagamento. O pagamento via boleto bancário leva de 24 horas a 72 horas contadas em dias úteis. Sendo o
          pagamento feito por boleto bancário, o acesso à plataforma HO Fácil poderá levar até 72h contadas a partir do pagamento
          do boleto. Caso o pagamento do boleto seja feito por agendamento, o prazo começará a ser contado na data de pagamento do agendamento.
        </p>
      </template>
    </p>

    <div
      class="credit_card-form"
      v-if="payment.method === 'credit_card'"
    >
      <div class="d-flex">
        <base-input-text
          v-model="v$.payment.data.first_name.$model"
          type="text"
          block
          label="Nome impresso no cartão"
          placeholder="Nome impresso no cartão"
          :error="getError(v$.payment.data.first_name)"
        />

        <base-input-text
          v-model="v$.payment.data.last_name.$model"
          type="text"
          block
          style='margin-left: 10px'
          label="Sobrenome impresso no cartão"
          placeholder="Sobrenome impresso no cartão"
          :error="getError(v$.payment.data.last_name)"
        />
      </div>

      <base-input-text
        v-model="v$.payment.data.number.$model"
        type="text"
        label="Número"
        placeholder="0000 0000 0000 0000"
        v-mask="'#### #### #### ####'"
        :error="getError(v$.payment.data.number)"
      />
      <!-- <div class="group"> -->
      <div class="validity">
        <base-input-select
          v-model="v$.payment.data.month.$model"
          type="text"
          label="Mês"
          placeholder="Mês"
          :options="monthOptions"
        />
        <base-input-select
          v-model="v$.payment.data.year.$model"
          type="text"
          label="Ano"
          placeholder="Ano"
          :options="yearOptions()"
        />
        <base-input-text
          v-model="v$.payment.data.cvv.$model"
          type="text"
          label="Código de Segurança"
          placeholder="000"
          v-mask="['###', '####', '#####']"
        >
          <template #icon-label>
            <base-tooltip>
              <template #body>
                <i class="fa fa-info-circle"/>
              </template>
              <template v-slot:message>
                O código de segurança fica impresso no verso do cartão de crédito.
              </template>
            </base-tooltip>
          </template>
        </base-input-text>
        <div class="cc-back">
          <img src="../../../../assets/images/cc-icon.png"/>
        </div>

      </div>
      <!-- </div> -->
      <base-input-select
        v-if="installmentOptions.length > 0"
        v-model="v$.payment.data.installments.$model"
        type="text"
        label="Quantidade de parcelas"
        placeholder="Selecione a quantidade de parcelas"
        :options="installmentOptions"
        :error="getError(v$.payment.data.installments)"
        _key="value"
        _value="text"
      />
    </div>

    <div class="buttons">
      <base-button
        class='w-50'
        block
        dark
        @click="$emit('back-step')"
      >
        Voltar
      </base-button>
      <b-overlay
        :show="loading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block w-50"
      >
        <base-button
          :disabled="!canNext()"
          block
          @click="handleSubmitPaymentMethod"
        >
          Próximo
        </base-button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { getYear } from 'date-fns'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, maxLength } from '@/utils/validators'
import IUGU from '@/services/iugu'
import BaseInputRadio from '../../../Base/BaseInputRadio.vue'

export default {
  components: { BaseInputRadio },
  name: 'Payment',
  props: {
    installmentOptions: { type: Array, required: true },
    namePlan: { type: String, required: true }
  },
  directives: { mask },
  setup () {
    return { v$: useVuelidate() }
  },
  computed: {
    ...mapGetters('checkout2', {
      billingData: 'getBillingData',
      payment: 'getPayment',
      promotion: 'getPromotion',
      isCreditCard: 'getIsCreditCard',
      getPlan: 'getPlan',
    }),
    ...mapGetters('promocodes', {
      discount: 'discount',
    }),
    user() {
      return this.$store.getters['user/current']
    },
    getEmailIndication() {
      return this.$store.getters['checkout2/getEmailIndication']
    },
  },
  data(){
    return {
      havePromotionCode: false,
      loading: false,
      discountCoupon: '',
      monthOptions: [
        { _id: '1', value: '1 - Janeiro'},
        { _id: '2', value: '2 - Fevereiro'},
        { _id: '3', value: '3 - Março'},
        { _id: '4', value: '4 - Abril'},
        { _id: '5', value: '5 - Maio'},
        { _id: '6', value: '6 - Junho'},
        { _id: '7', value: '7 - Julho'},
        { _id: '8', value: '8 - Agosto'},
        { _id: '9', value: '9 - Setembro'},
        { _id: '10', value: '10 - Outubro'},
        { _id: '11', value: '11 - Novembro'},
        { _id: '12', value: '12 - Dezembro'},
      ],
      methodsOptions: [
        { key: 'bank_slip', value: 'Boleto bancário e PIX'},
        { key: 'credit_card', value: 'Cartão de crédito'},
      ],
    }
  },
  validations: {
    payment: {
      method: { required },
      data: {
        first_name: { required },
        last_name: { required },
        number: { required, minLength: minLength(18) },
        month: { required },
        year: { required },
        cvv: { required, minLength: minLength(3), maxLength: maxLength(5) },
        installments: { required }
      }
    },
  },
  methods: {
    ...mapActions('checkout2', {
      handleSetPromotion: 'handleSetPromotion',
      handleSetPayment: 'handleSetPayment',
      handleGetTokenCreditCard: 'handleGetTokenCreditCard',
      handleSetAlertDetails: 'handleSetAlertDetails',
      handleResetAlertDetails: 'handleResetAlertDetails',
    }),
    ...mapActions('promocodes', {
      getPromotion: 'getPromo',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    getError(value){
      if(value.$errors.length > 0){
        return value.$errors[0].$message
      }
      return ''
    },
    yearOptions(){
      const now = new Date()
      const currentYear = getYear(now)
      const yearOptions = []
      const CURRENT_YEAR_ADD_15_YEARS = currentYear + 15

      for(let year = currentYear; year <= CURRENT_YEAR_ADD_15_YEARS; year += 1){
        yearOptions.push({
          _id: year,
          value: year
        })
      }
      return yearOptions
    },
    selectPaymentMethod(method){
      if(method === 'bank_slip'){
        this.handleSetPayment({
          method,
          data: {
            installments: 1
          }
        })
      } else if(method === 'credit_card') {
        this.handleSetPayment({
          method,
          data: {
            first_name: '',
            last_name: '',
            number: '',
            month: '',
            year: '',
            cvv: '',
            installments: 1
          }
        })
      }
    },
    canNext(){
      if(!this.payment.method) return false

      if(this.isCreditCard) {
        if(this.v$.payment.data.$invalid) return false
      }

      return true
    },
    async handleSubmitPaymentMethod(){
      this.loading = true;
      if(this.payment.method === "credit_card"){
        const response = await this.handleGetTokenCreditCard()

        if(response.error || response.token.error) {
          await this.handleSetAlertDetails({
            open: true,
            message: response.error ? response.error : response.token.error,
            type: 'danger',
          })
          const payload = {
            sent: '-',
            status: 'failed',
            responseError: response ? response : '-',
            user: this.user ? this.user : '-',
          }
          await this.$api
            .post('users/sendErrorTokenCardMail', payload)
            .then((res) => { })
            .catch(() => {})
          this.loading = false;
          return
        }
        const payload = {
          sent: '-',
          status: 'success',
          responseError: '-',
          user: this.user ? this.user : '-',
        }
        await this.$api
          .post('users/sendErrorTokenCardMail', payload)
          .then((res) => { })
          .catch(() => {})

        this.payment.token = response.token
        this.payment.installments = this.payment.data.installments
      }

      await this.handleResetAlertDetails()

      if(this.user && this.user.role !== 'admin') {
        const log = {
          action: 'PREENCHEU_DADOS_PAGAMENTO'
        }
        await this.createUserActionLogs(log)
      }
      this.loading = false;
      this.$emit('next-step')
    },
    async checkPromotion() {
      const payload = {
        user: this.billingData.document.replace(/\D+/g, ''),
        code: this.discountCoupon,
      }

      const discount = await this.getPromotion(payload)

      if(discount > 0){
        await this.handleSetAlertDetails({
          open: true,
          message: 'Desconto aplicado com sucesso!',
          type: 'success',
        })
      } else {
        await this.handleSetAlertDetails({
          open: true,
          message: 'Cupom inválido!',
          type: 'danger',
        })
      }
      this.promotion.code = this.discountCoupon
      this.handleSetPromotion({code: this.promotion.code})
    },
  },
  mounted(){
    IUGU.setup()
    this.discountCoupon = this.promotion.code
  }
}
</script>

<style lang="scss" scoped>
.payment {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .credit_card-form {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .group, .validity {
      display: grid;
      gap: 16px;
    }
    .group {
      grid-template-columns: 1fr 1fr;
      @media only screen and (max-width: 720px) {
        grid-template-columns: 1fr;
      }
    }
    .validity {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .cc-back {
        display: flex;
        align-items: flex-end;
        // justify-content: center;
        padding: 5px;
        img {
          width: 40px;
          height: auto;
        }
      }
    }
  }

  .promotion {
    a {
      color: var(--blue);
      &:hover {
        text-decoration: underline;
      }
    }
    .check-code {
      display: flex;
      align-items: flex-end;
      gap: 8px;
    }
  }

  .buttons {
    display: flex;
    gap: 16px;
  }
}
</style>
