<template>
  <header>
    <div
      class="step"
      v-for="(step, index) in steps"
      :key="step.key"
      :class="{
        highlight: currentStep.key === step.key,
      }"
    >
      <span class="number">
        {{(index + 1)}} -
      </span>
      <span class="name">
        {{step.name}}
      </span>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderSteps',
  props: {
    steps: { type: Array, required: true },
    currentStep: { type: Object, required: true },
  },
}
</script>

<style lang="scss" scoped>
header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  @media only screen and (max-width: 720px) {
    gap: 8px;
  }

  .step {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    justify-content: space-between!important;
    background: var(--white);
    color: var(--navy-blue);
    padding: 8px 8px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;

    transition: all 0.2s ease-in-out;

    border: 2px solid var(--navy-blue);

    @media only screen and (max-width: 720px) {
      font-size: 12px;
      padding: 4px 8px;
    }

    &.highlight, &.disabled {
      background: var(--navy-blue);
      color: var(--white);
      .number {
        color: var(--white);
      }
    }

    &.disabled:not(.highlight) {
      opacity: 0.7;
    }

    .number {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 16px;

      color: var(--navy-blue);

      transition: all 0.2s ease-in-out;

      @media only screen and (max-width: 720px) {
        display: none;
      }
    }
  }
}
</style>
