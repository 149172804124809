<template>
  <div class="checkout-finished">
    <div class="title">
      <div class="icons">
        <img src="../../assets/images/ho-facil-blue.png" alt="HOFACIL" >
        <i class="far fa-check-circle"/>
      </div>
      <span>Parabéns, pedido feito com sucesso!</span>
    </div>
    <div class="infos">
      <h3><i class="fas fa-exclamation-triangle"/> Importante:</h3>
      <span v-if="paymentMethod === 'credit_card'">
        A solicitação de compra foi realizada com sucesso e o pedido está
        sendo processado. Você já pode
        <router-link :to="{ name: 'Signin' }">acessar</router-link> sua conta
        e em breve as funcionalidades serão liberadas.
      </span>
      <span v-else>
        A solicitação de compra foi realizada com sucesso e o pedido está
        sendo processado. Após o pagamento do boleto, o sistema leva até 3
        dias úteis para compensar o pagamento. Assim que a compensação for
        realizada estaremos liberando acesso às funcionalidades premium do HO
        Fácil Web.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutFinished',
  props: {
    paymentMethod: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
.checkout-finished {
  max-width: 632px;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 32px;

  background: var(--white);
  

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    span {
      font-size: 24px;
      font-weight: 700;

      color: var(--blue);
    }

    div.icons {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        max-width: 92px;
      }

      i {
        font-size: 72px;
        color: var(--blue);
      }
    }
  }

  .infos {
    h3 {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>