<template>
  <section class='p-1'>
    <div v-if='term && term._id' style='margin: 1rem 0'>
      <Terms
        title='CONTRATO DE ADESÃO'
        :link='term.adhesionContractUrl'
        textLink='Tenha acesso ao contrato de adesão da Ho Fácil Web'
      />
    </div>

    <b-input-group class='mt-2 mb-5'>
      <p>
        <b-form-checkbox v-model='v$.billingData.adhesionContractAccepted.$model'>
          <strong>Eu estou ciente e de acordo com as condições contratuais.</strong>
        </b-form-checkbox>
      </p>
    </b-input-group>

    <div class='mt-3'>
      <b-alert
        show
        variant='danger'
        :class='{ hidden: !errors }'
      >
        {{ errors }}
      </b-alert>
    </div>

    <section class='buttons d-flex '>
      <base-button
        class='w-50 mr-2'
        block
        dark
        @click='back()'
      >
        Voltar
      </base-button>

      <base-button
        class='w-50'
        @click='next()'
      >
        Próximo
      </base-button>
    </section>

  </section>
</template>

<script>
import Terms from '@/components/Terms'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@/utils/validators'
import useVuelidate from '@vuelidate/core'

export default {
  name: 'AdhesionContract',
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    Terms
  },
  props: {},
  async mounted() {

  },
  data() {
    return {
      acceptedTerms: false
    }
  },
  computed: {
    term() {
      return this.$store.getters['term/term']
    },
    errors() {
      return this.$store.getters['access/error']
    },
    ...mapGetters('checkout2', {
      billingData: 'getBillingData'
    })
  },
  validations() {
    return {
      billingData: {
        adhesionContractAccepted: { required }
      }
    }
  },
  methods: {
    ...mapActions('term', {
      handleGet: 'handleGet'
    }),
    async next() {
      await this.$store.dispatch('access/updateError', ``)
      if (!this.billingData.adhesionContractAccepted) {
        await this.$store.dispatch('access/updateError', `Clique em "Eu estou ciente e de acordo com as condições contratuais" para continuar.`)
        return
      }
      this.$emit('next-step')
    },
    async back() {
      await this.$router.push('/planos')
    }
  },

  async created() {
    try {
      await this.$store.dispatch('access/updateError', ``)
      await this.handleGet()
      this.billingData.adhesionContractUrl = this.term.adhesionContractUrl
      this.billingData.adhesionContractDate = this.term.adhesionContractDate
      this.billingData.adhesionContractName = this.term.adhesionContractName
    } catch (e) {

    }
  }
}
</script>

<style lang='scss' scoped>
.validation-title {
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.validation-title-color-blue {
  color: #04A8F3;
}

.validation-title-color-black {
  color: #262626;
}

.validation-text-type-plan {
  color: #262626;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.validation-text-information {
  color: #262626;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.obs {
  display: flex;
  align-items: center;
  justify-content: start;
  background: rgba(4, 168, 243, 0.4);
  border-radius: 6.25px;
  padding: 10px;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
}

.obs-text {
  color: var(---navy-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.limits {
  list-style: inside;
  margin-left: 30px;

  li {
    margin-bottom: 5px;
    list-style-position: outside;
  }
}
</style>
