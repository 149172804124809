<template>
  <div class="plan-details">
    <header>
      <span>{{ plan.name }}</span>
    </header>

    <main>
      <span class="description">
        {{ plan.description }}
      </span>

      <span>
        {{ plan.refund }}
      </span>

      <span class="price">
        <span v-if="discount" class="subtitle">de</span>
        <span v-if="installments > 1 && !discount" :class="{ discount }">
          {{ installments }} x
          {{ formatRealCurrent(priceForPromotion / installments) }}
        </span>
        <span :style="!user && this.namePlan === 'FLEXIVEL' ? 'filter: blur(10px);' : ''" v-else :class="{ discount }">
          {{ formatRealCurrent(installments > 3 ? priceForPromotion : finalPrice)}}
        </span>
      </span>

      <!--<div class="countdown-container" v-if="planIsPromotion(namePlan)">
        <span v-if="counterLegend">{{counterLegend}}</span>
        <Countdown
          @block-plan="$emit('block-plan')"
          :date="counterDate"
        />
      </div>-->
      <span class="price-discount" v-if="discount">
        <span v-if="discount" class="subtitle">por</span>
        <span v-if="installments > 1">
          {{ installments }} x
          {{ formatRealCurrent(priceForPromotion / installments) }}
        </span>
        <span v-else>
          {{ formatRealCurrent(priceForPromotion) }}
        </span>
      </span>
    </main>
    <footer>
      <span class="title"> Métodos de pagamento aceitos: </span>
      <div class="payment-flags">
        <img alt="Boleto" src="../../../assets/images/pagamento/boleto.svg" />
        <img alt="Amex" src="../../../assets/images/pagamento/amex.svg" />
        <img
          alt="Dinner`s club"
          src="../../../assets/images/pagamento/dinners.svg"
        />
        <img alt="ELO" src="../../../assets/images/pagamento/elo.svg" />
        <img
          alt="Mastercard"
          src="../../../assets/images/pagamento/master.svg"
        />
        <img alt="Visa" src="../../../assets/images/pagamento/visa.svg" />
        <img class='mb-1 ml-1' style='box-shadow: rgba(100, 100, 111, 0.2) 1px 2px 2px 1px; padding: 2px 5px; border-radius: 3px' width="56" height="36" alt='PIX' src='../../../assets/images/pagamento/pix.svg' />
      </div>
      <span class="by">
        Ambiente e transações 100% seguros por
        <a href="https://iugu.com" target="_blank">iugu</a>
      </span>
    </footer>
  </div>
</template>

<script>
//import Countdown from "./Countdown"
import { formatRealCurrent } from '@/utils/format'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PlanDetails',
  props: {
    namePlan: { type: String, required: true },
    plans: { type: Object, required: true },
    code: { type: String },
    installments: { type: Number, required: true },
    discount: { type: Number, required: true },
  },
  components: {
    /*Countdown*/
  },
  data() {
    return {
      counterLegend: '',
      counterDate: null,
      plan: {},
      promotionPlans: ['PROMO', 'PROMO2'],
      promotionNotStarted: false,
      taxaJuros: 1.49,
    }
  },
  computed: {
    ...mapGetters('checkout2', {
      promotion: 'getPromotion',
      billingData: 'getBillingData',
    }),
    user() {
      return this.$store.getters['user/current']
    },
    priceForPromotion() {
      let discount = this.discount
      if (this.promotion && this.promotion.code === 'RENEW' && this.discount) {
        discount = (parseFloat(discount) *  parseFloat(this.plan.value)) / 100
      }
      this.handleSetdiscount(discount)
      this.calculateTotalPlan()
      if (this.installments <= 3) {
        return (parseFloat(this.plan.value) - discount) + Number(this.billingData.precoCompraUsuariosSubordinados)
      } else {
        if (!discount) {
          discount = 0
        }
        const priceTotal = ((parseFloat(this.plan.value) - parseFloat(discount)) + Number(this.billingData.precoCompraUsuariosSubordinados)) * Math.pow(1 + this.taxaJuros / 100, this.installments)
        return priceTotal
      }
    },
    finalPrice() {
      let discount = this.discount
      if (this.promotion && this.promotion.code === 'RENEW' && this.discount) {
        discount = (Number(discount) *  Number(this.plan.value)) / 100
      }
      this.handleSetdiscount(discount)
      this.calculateTotalPlan()
      if (this.installments <= 3) {
        return parseFloat(this.plan.value) + Number(this.billingData.precoCompraUsuariosSubordinadosSemDesconto)
      } else {
        return (parseFloat(this.plan.value) * Math.pow(1 + this.taxaJuros / 100, this.installments))  + Number(this.billingData.precoCompraUsuariosSubordinadosSemDesconto)
      }
    },
  },
  methods: {
    ...mapActions('checkout2', {
      handleSetdiscount: 'handleSetdiscount',
      calculateTotalPlan: 'calculateTotalPlan',
    }),
    formatRealCurrent,
    planIsPromotion(namePlan) {
      const exists = this.promotionPlans.find((plan) => plan === namePlan)

      return exists ? true : false
    },
    init(){
      if (this.namePlan) {
        this.plan = this.plans[this.namePlan]

        if (this.planIsPromotion(this.namePlan)) {
          const today = new Date(this.plan.today)
          const validFrom = new Date(this.plan.validFrom)
          const validUntil = new Date(this.plan.validUntil)
          if (today.getTime() <= validFrom.getTime()) {
            this.counterLegend = 'Promoção começa em:'
            this.counterDate = this.plan.validFrom
            this.$emit('block-plan')
          } else if (
            today.getTime() >= validFrom.getTime() &&
            today.getTime() <= validUntil.getTime()
          ) {
            this.counterLegend = 'Promoção termina em:'
            this.counterDate = this.plan.validUntil
            this.$emit('block-plan')
          }
        }
      }
    }
  },
  mounted() {
    this.init()
  },
  watch: {
      plans() {
        this.init()
      },
  },
}
</script>

<style lang="scss" scoped>
.plan-details {
  max-width: 330px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  background: var(--white);

  @media only screen and (max-width: 720px) {
    max-width: 100%;
  }

  header {
    text-align: center;
    padding: 8px 0;

    border-bottom: 1px solid #999999;

    span {
      font-size: 18px;
      font-weight: bold;
      color: #999999;
    }
  }
  main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    padding: 0 16px;

    font-size: 16px;

    .description {
      font-style: italic;
    }

    .price,
    .price-discount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 32px;
      font-weight: bold;
      text-align: center;

      span.discount {
        text-decoration: line-through;
      }

      .subtitle {
        font-size: 12px;
      }
    }

    .countdown-container {
      span {
        font-size: 18px;
        font-weight: 700;
      }
    }
  }
  footer {
    padding: 0 16px 16px;

    .title,
    .by {
      font-size: 10px;
    }
    .payment-flags {
      padding: 0 16px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
</style>
