<template>
  <section class='p-1'>
    <section>
      <p class='validation-title'>
        <span class='validation-title-color-black'>Você selecionou </span>
        <span class='validation-title-color-blue'>o plano mensal</span>
      </p>
    </section>

    <section>
      <p class='validation-text-type-plan'>Tipo de plano</p>

      <p class='validation-text-information'>Assinatura Mensal. Cobrança mensal que cabe no seu bolso!</p>
      <p class='validation-text-information'>07 dias de garantia com reembolso integral</p>
    </section>

    <section class='obs mb-4 mt-3'>
      <img width='25' height='25' src='../../../../assets/images/Obs.svg' alt=''>

      <section>
        <p class='ml-2 mt-3 obs-text'>
          Reforçamos que a assinatura mensal é <strong>cobrada de forma automática </strong> todo mês a menos que
          cancelada de forma <strong>manual.</strong>
        </p>

        <section class='ml-2 limits '>
          <strong>Limites do plano mensal:</strong>
          <ul class='limits mt-1'>
            <li class='obs-text'>Pesquisa e seleção de até 300 agentes químicos por mês</li>
            <li class='obs-text'>Sem informações precisas e segurança para fundamentar o seu GRO (Gerenciamento de Riscos Ocupacionais)</li>
            <li class='obs-text'>Sem permissão para adquirir acessos adicionais</li>
          </ul>
        </section>
      </section>
    </section>
    <section class='buttons d-flex'>
      <base-button
        class='w-50 mr-2'
        block
        dark
        @click="$emit('back-step')"
      >
        Voltar
      </base-button>

      <base-button
        class='w-50'
        @click='next()'
      >
        Concordo e desejo continuar
      </base-button>
    </section>

  </section>
</template>

<script>

export default {
  name: 'Validation',
  props: {},
  async mounted() {

  },
  data() {
    return {}
  },
  computed: {},

  methods: {
    async next() {
      this.$emit('next-step')
    },
    async back() {
      await this.$router.push('/planos')
    }
  },

  created() {
    try {
    } catch (e) {

    }
  }
}
</script>

<style lang='scss' scoped>
.validation-title {
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.validation-title-color-blue {
  color: #04A8F3;
}

.validation-title-color-black {
  color: #262626;
}

.validation-text-type-plan {
  color: #262626;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.validation-text-information {
  color: #262626;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.obs {
  display: flex;
  align-items: center;
  justify-content: start;
  background: rgba(4, 168, 243, 0.4);
  border-radius: 6.25px;
  padding: 10px;
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
}

.obs-text {
  color: var(---navy-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.limits{
  list-style: inside;
  margin-left: 30px;
  li{
    margin-bottom: 5px;
    list-style-position: outside;
  }
}
</style>
