<template>
  <div class="confirmation" v-if="!disabled">
    <div class="billing-data" v-if="billingData">
      <h2>Dados do faturamento</h2>
      <div class="groups">
        <div class="group">
          <h3>Informações</h3>
          <span v-if="billingData.name">
            <strong>Nome: </strong>
            {{billingData.name}}
          </span>
          <span v-if="billingData.document">
            <strong>CPF: </strong>
            {{billingData.document}}
          </span>
          <span v-if="billingData.email">
            <strong>Email: </strong>
            {{billingData.email}}
          </span>
          <span v-if="billingData.companyName">
            <strong>Nome da empresa: </strong>
            {{billingData.companyName}}
          </span>
          <span v-if="billingData.cnpj">
            <strong>CNPJ: </strong>
            {{billingData.cnpj}}
          </span>
          <span v-if="billingData.stateRegister">
            <strong>Inscrição estadual: </strong>
            {{billingData.stateRegister}}
          </span>
          <span v-if="billingData.cityRegister">
            <strong>Inscrição municipal: </strong>
            {{billingData.cityRegister}}
          </span>
        </div>
        <div class="group">
          <h3>Endereço</h3>
          <span v-if="billingData.address.street && billingData.address.number">
            <strong>Rua: </strong>
            {{billingData.address.street}},
            {{billingData.address.number}}
          </span>
          <span v-if="billingData.address.complement">
            <strong>Complemento: </strong>
            {{billingData.address.complement}}
          </span>
          <span v-if="billingData.address.neighborhood">
            <strong>Bairro: </strong>
            {{billingData.address.neighborhood}}
          </span>
          <span v-if="billingData.address.city">
            <strong>Cidade: </strong>
            {{billingData.address.city}}
          </span>
          <span v-if="billingData.address.state">
            <strong>Estado: </strong>
            {{billingData.address.state}}
          </span>
          <span v-if="billingData.address.cep">
            <strong>CEP: </strong>
            {{billingData.address.cep}}
          </span>
        </div>
      </div>
    </div>
    <div class="payment" v-if="payment">
      <h2>Dados de Pagamento</h2>
      <span v-if="payment.method">
        <strong>Método: </strong>
        {{methods[payment.method]}}
      </span>
      <span class="payment-method-notes">
        <template v-if="payment.method == 'credit_card'">
          A liberação do acesso à plataforma HOFácil Web acontece de maneira automática a partir do retorno positivo da compensação financeira advinda da instituição financeira utilizada para realizar o pagamento.
          O pagamento via cartão de crédito pode levar até 5 minutos para ser analisado e compensado.
        </template>
        <template v-else>
          <p>
            <strong>Quando paga via PIX:</strong> a liberação do acesso à plataforma HO Fácil Web acontece em até 15 minutos.
          </p>

          <p>
            <strong>Quando paga via boleto bancário:</strong> a liberação do acesso à plataforma HO Fácil Web acontece de maneira
            automática a partir do retorno positivo da compensação financeira advinda da instituição financeira utilizada para
            realizar o pagamento. O pagamento via boleto bancário leva de 24 horas a 72 horas contadas em dias úteis. Sendo o
            pagamento feito por boleto bancário, o acesso à plataforma HO Fácil poderá levar até 72h contadas a partir do pagamento
            do boleto. Caso o pagamento do boleto seja feito por agendamento, o prazo começará a ser contado na data de pagamento do agendamento.
          </p>
        </template>
      </span>
    </div>
    <div class="buttons">
      <base-button
        block
        dark
        @click="$emit('back-step')"
      >
        Voltar
      </base-button>
      <base-button
        block
        @click="submitCheckout"
        :disabled="disabled"
      >
        Finalizar Compra
      </base-button>
    </div>
  </div>
  <div class="process-purchase" v-else>
    <div class='d-flex justify-content-end flex-column align-items-center'>
      <div class='mb-3'>
        <h5 v-if="tentativa === 1"><strong>Aguarde enquanto estamos tentando processar seu pagamento.</strong></h5>
        <h5 v-else-if="tentativa === 2"><strong>Estamos tendo lentidão ao processar pagamento, por favor, aguarde mais um pouco.</strong></h5>
        <h5 v-else><strong>Por favor, aguarde mais um pouco</strong></h5>
      </div>

      <div >
        <b-spinner style="width: 4rem; height: 4rem; font-size: 30px" variant="info"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Confirmation',
  computed: {
    ...mapGetters('checkout2', {
      payment: 'getPayment',
      billingData: 'getBillingData',
    }),
    user() {
      return this.$store.getters['user/current']
    },
  },
  data(){
    return {
      disabled: false,
      tentativa: 0,
      methods: {
        'credit_card': 'Cartão de crédito',
        'bank_slip': 'Boleto bancário e PIX',
      },
    }
  },
  methods: {
    ...mapActions('checkout2', {
      handleSubmitCheckout: 'handleSubmitCheckout',
      handleSetAlertDetails: 'handleSetAlertDetails',
      handleResetAlertDetails: 'handleResetAlertDetails',
      handleResetSteps: 'handleResetSteps',
      handleResetPayment: 'handleResetPayment',
      handleResetBillingData: 'handleResetBillingData',
      handleResetPromotion: 'handleResetPromotion',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    async submitCheckout(){
      try{
        this.disabled = true
        this.tentativa++;
        const response = await this.handleSubmitCheckout()
        if (response.errors) {
          const errorRaw = response.errors
          let error = errorRaw || 'Dados inválidos, verifique e tente novamente!'

          if(errorRaw['payer.address.zip_code'] || errorRaw['zip_code']){
            error = 'CEP inválido, verifique e tente novamente!'
          }

          if(errorRaw['payer.cpf_cnpj'] || errorRaw['cpf_cnpj']){
            error = 'CPF/CNPJ inválido, verifique e tente novamente!'
          }

          if(errorRaw['plan_identifier']){
            error = 'Plano inválido, verifique e tente novamente!'
          }

          if(errorRaw['token'] || errorRaw === 'Esse token já foi usado.' || errorRaw === 'This token has already been used'){
            error = 'Por favor preencha os dados do cartão novamente'
            this.$emit('back-step')
          }

          await this.handleSetAlertDetails({
            open: true,
            type: 'danger',
            message: error
          })
          this.disabled = false;
        } else {
          const bankSlip = response.bankSlip || ''

          if (bankSlip) {
            window.open(bankSlip, '_blank')
          }
          if (response.sucess) {
            await this.handleSetAlertDetails({
              open: true,
              type: 'success',
              message: 'Pedido efetivado com sucesso!'
            })

            if (this.user && this.user.role !== 'admin') {
              const log = {
                action: 'FINALIZOU_COMPRA'
              }
              await this.createUserActionLogs(log)
            }
            window.location.replace('http://hofacilweb.com.br/?page_id=249')
          } else {
            await this.handleSetAlertDetails({
              open: true,
              type: 'danger',
              message: 'Não foi possível processar seu pagamento, tente novamente mais tarde!!'
            })
          }
          this.disabled = false;
        }
      } catch(e) {
        console.log('e')
        console.log(e)
        console.log('e')
        if(this.tentativa < 3) {
          setTimeout(async () => {
            await this.submitCheckout();
          }, 22500)
        } else {
          this.disabled = false;
          this.tentativa = 0;
          await this.handleSetAlertDetails({
            open: true,
            type: 'danger',
            message: 'Não foi possível processar seu pagamento, tente novamente mais tarde!!!'
          })
        }
      } finally {
        //this.disabled = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-spinner{
  width: 2.5rem;
  height: 2.5rem;
  margin: -15px 0 0 10px;
}
.confirmation {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    font-size: 24px;
    font-weight: 700;
  }
  h3 {
    font-size: 18px;
    font-weight: 700;
  }
  .billing-data, .payment, .group {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .billing-data {
    .groups {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
    }
  }
  .buttons {
    display: flex;
    gap: 16px;
  }
  .payment-method-notes {
    font-size: 14px;
    font-style: italic;
  }
}

.process-purchase{
  height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
