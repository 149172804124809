<template>
  <div class="view-checkout">
    <img alt="Ho Facil logo" src="../assets/images/ho-facil-blue.png" />

    <base-alert2
      v-if="alert.open"
      class="alert"
      :type="alert.type"
      :message="alert.message"
      @close="alert.open = false"
    />

    <not-logged
      v-if="!user && chosenPlan && !userProfile.register"
      :plan="redirectParameters"
      @register="userProfile.register = true"
    />

    <main v-if="chosenPlan && !userProfile.register">
      <checkout-finished
        v-if="checkoutFinished.open"
        :payment-method="checkoutFinished.method"
      />
      <checkout-steps
        v-if="!checkoutFinished.open"
        :installment-options="installmentOptions"
        :name-plan="chosenPlan"
        :block-next="blockPlan"
        @checkout-finished="handleCheckoutFinished"
      />
      <checkout-plan
        v-if="plans && !checkoutFinished.open"
        :name-plan="chosenPlan"
        :plans="plans"
        :installments="installments"
        :discount="discount || 0"
        @block-plan="blockPlan = true"
        :code="$route.query.renew"
      />
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NotLogged from '@/components/Checkout2/NotLogged'
import CheckoutPlan from '@/components/Checkout2/Plan/index'
import CheckoutSteps from '@/components/Checkout2/Steps/index'
import CheckoutFinished from '@/components/Checkout2/Finished'
export default {
  name: 'ViewCheckout',
  data(){
    return {
      chosenPlan: '',
      redirectParameters: {},
      register: false,
      blockPlan: false,
      checkoutFinished: {
        method: '',
        open: false
      },
      planAction: {
        AN1HO: 'SELECIONOU_PLANO_ANUAL_ADERIR',
        MO1HO: 'SELECIONOU_PLANO_MENSAL_ADERIR',
        PROMO: 'SELECIONOU_PLANO_PROMOCIONAL_ADERIR',
      }
    }
  },
  components: {
   CheckoutPlan,
   CheckoutSteps,
   CheckoutFinished,
   NotLogged,
  },
  computed: {
    ...mapGetters('plans', {
      plans: 'getPlans',
    }),
    ...mapGetters('checkout2', {
      payment: 'getPayment',
      alert: 'getAlertDetails',
      plan: 'getPlan',
      promotion: 'getPromotion',
      billingData: 'getBillingData',
    }),
    ...mapGetters('promocodes', {
      discount: 'discount',
    }),
    ...mapGetters('user', {
      user: 'current',
    }),
    installments() {
      return this.payment.data ? Number(this.payment.data.installments) : 0
    },
    installmentOptions() {
      let options = []
      if(this.plans[this.chosenPlan]) {
        if(this.plans[this.chosenPlan].monthOptions){
          options = this.plans[this.chosenPlan].monthOptions
        }
      }
      return options
    },
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
  },
  methods: {
    ...mapActions('checkout2', {
      handleSetPlan: 'handleSetPlan',
      handleSetAlertDetails: 'handleSetAlertDetails',
      handleSetPromotion: 'handleSetPromotion',
      handleSetStepUnshift: 'handleSetStepUnshift',
      handleResetSteps: 'handleResetSteps',
      handleSetEmailindication: 'handleSetEmailindication',
    }),
    ...mapActions('plans', {
      handleGetPlans: 'handleGetPlans',
      handleSetPlans: 'handleSetPlans',
      handleSetPlanCuston: 'handleSetPlanCuston',
    }),
    ...mapActions('promocodes', {
      getPromotion: 'getPromo',
    }),

    ...mapActions('user', {
      handleSetCurrentUser: 'handleSetCurrentUser',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    ...mapActions('linkCheckoutsDifferentPrices', {
      handleSearchPriceLinkByCode: 'handleSearchPriceLinkByCode',
    }),
    ...mapActions('tasksQueue', {
      addTasksQueue: 'addTasksQueue',
    }),
    ...mapActions('auth', {
      handleSaveLocalStorageSessionId: 'handleSaveLocalStorageSessionId'
    }),
    handleCheckoutFinished(paymentMethod) {
      this.checkoutFinished = {
        method: paymentMethod,
        open: true
      }
    },
    handleRegistered(user) {
      this.handleSetCurrentUser(user)
      this.userProfile.register = false
    },
    async checkPromotion(code) {
      await this.getPromotion({code})
    },
    resetSteps(){
      let steps = [
        { key: 'billing-data', name: 'Dados de Faturamento', completed: false },
        { key: 'payment', name: 'Dados de Pagamento', completed: false },
        { key: 'confirmation', name: 'Confirmação', completed: false },
      ]

      if(this.plans[this.chosenPlan]['planInternal'] === 'AN1HO'|| this.chosenPlan === 'AN1HO' || this.chosenPlan === 'PROMO') {
        steps = [
          { key: 'number-accesses', name: 'Quantidade de acessos', completed: false },
          { key: 'billing-data', name: 'Dados de Faturamento', completed: false },
          { key: 'payment', name: 'Dados de Pagamento', completed: false },
          { key: 'confirmation', name: 'Confirmação', completed: false },
        ]
      }

      if(this.plans[this.chosenPlan]['planInternal'] === 'MO1HO'|| this.chosenPlan === 'MO1HO') {
        steps = [
          { key: 'validation', name: 'Validação', completed: false },
          { key: 'billing-data', name: 'Dados de Faturamento', completed: false },
          { key: 'payment', name: 'Dados de Pagamento', completed: false },
          { key: 'confirmation', name: 'Confirmação', completed: false },
        ]
      }
      steps.unshift({ key: 'AdhesionContract', name: 'Contratos', completed: false })
      this.handleSetStepUnshift(steps)
      this.handleResetSteps()
    }
  },
  async mounted() {
    try {
      const sessionId = localStorage.getItem("sessionId");
      if (sessionId !== null) {
        const { data, status } = await new Promise((resolve, reject) => {
          this.$api.get(`users/${sessionId}`)
            .then((data) => {
              return resolve(data)
            })
            .catch((error) => {
              return resolve(error.response)
            })
        })
        if (status !== 200) {
          await this.handleSaveLocalStorageSessionId(null)
          await this.$store.dispatch('user/updateUser', null);
        }
      } else {
        await this.handleSaveLocalStorageSessionId(null)
        await this.$store.dispatch('user/updateUser', null);
      }

      this.billingData.customPlanCode = ''
      await this.handleGetPlans()
      this.chosenPlan = this.$route.query.plano ? this.$route.query.plano : 'AN1HO'
      this.redirectParameters = this.$route.query
      if(this.user && this.$route.query.renew) {
        await this.checkPromotion(this.$route.query.renew)
        await this.handleSetPromotion({ code: this.$route.query.renew })
      } else if(this.user && this.$route.query.codigo) {
        const { data: response } = await this.handleSearchPriceLinkByCode(this.$route.query.codigo)
        if(response){
          await this.handleSetPlanCuston(response)
          this.chosenPlan = response['FLEXIVEL'].plan
          this.billingData.customPlanCode = this.$route.query.codigo
        } else {
          window.location.replace(`${process.env.VUE_APP_HOST}/checkout?plano=AN1HO`)
          this.chosenPlan = 'AN1HO'
          await this.handleGetPlans()
        }
      }
      if(!this.plans[this.chosenPlan]) {
        await this.$router.push({ name: 'NotFound' })
      } else {
        await this.handleSetPlan({
          ...this.plans[this.chosenPlan],
          code: this.chosenPlan
        })
        await this.handleSetEmailindication(this.$route.query.email ? this.$route.query.email : null)
        this.resetSteps()
        if(this.user && this.user.role !== 'admin') {
          let plan = this.chosenPlan
          if(this.chosenPlan === 'FLEXIVEL'){
            plan = this.plans[this.chosenPlan].planInternal
          }
          const action = this.planAction[plan]

          const log = {
            action
          }
          await this.createUserActionLogs(log)
          const job = {
            job: 'sendTagAbandonedCartActiveCampaign',
            dataProcessTask: {
              userId: this.user.id,
              plan
            }
          }
          await this.addTasksQueue(job)
        }
      }
    } catch (e) {

    }
  },
}
</script>

<style lang="scss" scoped>
.view-checkout{
  min-width: 98vw;
  min-height: 110vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;

  background-image: url('../assets/images/bg-ho-facil.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  img {
    margin-bottom: 16px;
  }

  .alert {
    max-width: 978px;
  }

  main {
    width: 100%;
    min-height: 150vh;
    display: flex;
    justify-content: center;
    gap: 16px;

    @media only screen and (max-width: 720px) {
      flex-direction: column-reverse;
    }
  }
}
</style>
