<template>
  <div class='billing-data'>
    <div>
      <p class='title_options'>Selecione a seguir a opção desejada</p>
      <div class='btn-radio w-100'>
        <b-form-group>
          <b-form-radio-group
            @change='setHits()'
            class='form-radio w-100'
            v-model='v$.billingData.hits.$model'
          >
            <div
              :class="billingData.hits === 'acessos_para_mim_minha_equipe' ? 'hits_select ':'hits_no_select'"
              class='mr-5'
            >
              <b-form-radio
                :value='acessosParaMimMinhaEquipe.key'
              >
                <p class='hits_value'>{{ acessosParaMimMinhaEquipe.value }}</p>
                <span class='hits_description' v-html='acessosParaMimMinhaEquipe.description'></span>
              </b-form-radio>
            </div>

            <div
              :class="billingData.hits === 'acesso_para_mim' ? 'hits_select ':'hits_no_select'"
              class='mr-5'
            >
              <b-form-radio
                :value='acessoParaMim.key'
              >
                <p class='hits_value'>{{ acessoParaMim.value }}</p>
              </b-form-radio>
            </div>
          </b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div style='min-height: 250px'>
      <div class='my-3' v-if="billingData.hits === 'acessos_para_mim_minha_equipe'">
        <p class='numberAccesses'>
          Selecione a quantidade adicional de acessos que você pretende contratar para seus colaboradores (descontos a
          partir de 30%):
        </p>

        <div class='group'>
          <b-form-select
            v-model='v$.billingData.numberAccesses.$model'
            :options='employees'
            class='mb-3'
            style='height: 50px'
            value-field='key'
            text-field='value'
            @change='calculateTotal()'
          >
            <template #first>
              <b-form-select-option :value='0' disabled>Selecione a quantidade de colaboradores</b-form-select-option>
            </template>
          </b-form-select>

          <div class='p-2' v-if='billingData.numberAccesses > 0'>
            <p>
              <strong>
                Você está comprando
                <span class='color_text'>{{ billingData.numberAccesses }} acesso(s).
                </span>
                O desconto aplicável para a compra de
                {{ billingData.numberAccesses }}
                acesso(s) é de
                {{ percentage(billingData.numberAccesses) }}%.
              </strong>
            </p>
            <p>
              <strong>
                Preço do {{this.getPlan.name}}:
                <span>
                  {{
                    formatRealCurrent((parseFloat(this.getPlan.value) - parseFloat(getDiscount)))
                  }}
                </span>
              </strong>
              <br/>


              <strong>
                {{billingData.numberAccesses > 1 ? 'Preço dos acessos adicionais:': 'Preço do acesso adicional:'}}
                <span>
                  {{
                    formatRealCurrent(parseFloat(billingData.precoCompraUsuariosSubordinados))
                  }}
                </span>
              </strong><br/>

              <strong>
                Total :
                <span>
                  {{
                    formatRealCurrent(Number(billingData.precoCompraUsuariosSubordinados) + (parseFloat(this.getPlan.value) - parseFloat(getDiscount)))
                  }}
                </span>
              </strong><br/>
            </p>
          </div>
        </div>
      </div>

      <div v-else class='d-flex justify-content-end my-3'>
        <p class='ml-5'>
          <strong>Total:
            <span>
              {{
                formatRealCurrent((parseFloat(this.getPlan.value) - parseFloat(getDiscount)))
              }}
            </span>
          </strong>
        </p>
      </div>
    </div>

    <section class='buttons d-flex'>
      <base-button
        class='w-50 mr-2'
        block
        dark
        @click="$emit('back-step')"
      >
        Voltar
      </base-button>

      <b-overlay
        :show='loading'
        rounded
        opacity='0.6'
        spinner-small
        spinner-variant='primary'
        class='d-inline-block w-50'
      >
        <base-button
          block
          @click='next()'
        >
          Próximo
        </base-button>
      </b-overlay>
    </section>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import moment from 'moment'
import { required } from '@/utils/validators'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { formatRealCurrent } from '@/utils/format'

export default {
  name: 'BillingData',
  setup() {
    return { v$: useVuelidate() }
  },
  props: {},
  async mounted() {
    this.billingData.numeroUsuariosExistentes = 0
    this.generateOptionsNumberEmployees()
    await this.calculateTotal()
  },
  data() {
    return {
      loading: false,
      disableButtonToNotBuyOutOfTimePlan: true,
      plans: {},
      access: [],
      additionalUserDiscount: 0.025,
      baseDiscount: 0.275,
      employees: [],
      diasEmUmAno: 365,
      numeroUsuariosExistentes: 0,
      totalPrice: 0,
      amountAccessCollaborator: 0,
      planPrice: 0,
      birthDate: '',
      document: '',
      plan: {},
      acessosParaMimMinhaEquipe: {
        key: 'acessos_para_mim_minha_equipe',
        value: 'Quero comprar acessos para mim e minha equipe',
        description: 'Muito bom saber que estaremos trazendo facilidade, <br/> produtividade e tranquilidade para você e sua equipe!'
      },
      acessoParaMim: {
        key: 'acesso_para_mim',
        value: 'Quero somente um acesso para mim'
      }
    }
  },
  directives: { mask },
  computed: {
    ...mapGetters('user', {
      user: 'current'
    }),
    ...mapGetters('checkout2', {
      billingData: 'getBillingData',
      getPlan: 'getPlan',
      getDiscount: 'getDiscount'
    }),
    ...mapGetters('plans', {
      getPlans: 'getPlans'
    }),
    ...mapGetters('promocodes', {
      discount: 'discount'
    })
  },

  validations() {
    return {
      billingData: {
        numberAccesses: {},
        hits: { required }
      }
    }
  },
  methods: {
    ...mapActions('checkout2', {
      handleSetPlan: 'handleSetPlan',
      handleSetBillingData: 'handleSetBillingData',
      handleGetAmountPaidPlan: 'handleGetAmountPaidPlan',
      calculateTotalPlan: 'calculateTotalPlan',
      handleSeNumberAccesses: 'handleSeNumberAccesses',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs'
    }),
    ...mapActions('plans', {
      handleSetPlanCuston: 'handleSetPlanCuston'
    }),
    formatRealCurrent,
    async setHits() {
      await this.handleSeNumberAccesses(0)
      await this.calculateTotal()
    },
    generateOptionsNumberEmployees() {
      for (let employee = 1; employee <= 10; employee++) {
        this.employees.push({
          key: employee,
          value: `${employee} ${employee > 1 ? 'colaboradores adicionais ' : 'colaborador adicional'} `
        })
      }
    },
    percentage(quantidadeColaboradores) {
      switch (quantidadeColaboradores) {
        case 1: {
          return 30
        }
        case 2: {
          return 32.5
        }
        case 3: {
          return 35
        }
        case 4: {
          return 37.5
        }
        case 5: {
          return 40
        }
        case 6: {
          return 42.5
        }
        case 7: {
          return 45
        }
        case 8: {
          return 47.5
        }
        default: {
          return 50
        }
      }
    },
    async calculateTotal() {
      this.access = []
      for (let i = 1; i <= this.billingData.numberAccesses; i++) {
        const percentage = this.percentage(i + this.amountAccessCollaborator)
        this.access.push(
          {
            quantidadeAcesso: i,
            discount: percentage.toString().replace('.', ',')
          }
        )
      }
      if (this.getPlan && this.getPlan.value) {
        await this.calculateTotalPlan()
      }
    },
    newPricePlan(price) {
      const plan = {
        AN1HO: {
          name: 'Plano anual',
          description:
            'Todas as funcionalidades do plano mensal e uma super aula de integração, treinamento e tira dúvidas ministrada pelo Eng. de Segurança Marcelo Augusto sobre o uso da plataforma. A aula focará em informações sobre o uso do software, eSocial, GRO, laudo de insalubridade e LTCAT com enfoque nos agentes químicos.',
          refund: '30 dias de garantia com reembolso integral',
          value: 0,
          method: 'charge',
          iuguData: {
            description: 'Plano Anual',
            quantity: 1,
            price_cents: 0
          },
          monthOptions: [
            { value: 1, text: '1x sem juros' },
            { value: 2, text: '2x sem juros' },
            { value: 3, text: '3x sem juros' },
            { value: 4, text: '4x com juros' },
            { value: 5, text: '5x com juros' },
            { value: 6, text: '6x com juros' },
            { value: 7, text: '7x com juros' },
            { value: 8, text: '8x com juros' },
            { value: 9, text: '9x com juros' },
            { value: 10, text: '10x com juros' },
            { value: 11, text: '11x com juros' },
            { value: 12, text: '12x com juros' }
          ]
        }
      }
      this.handleSetPlanCuston(plan)
      this.handleSetPlan({
        code: 'AN1HO',
        name: 'Plano anual',
        description:
          'Todas as funcionalidades do plano mensal e uma super aula de integração, treinamento e tira dúvidas ministrada pelo Eng. de Segurança Marcelo Augusto sobre o uso da plataforma. A aula focará em informações sobre o uso do software, eSocial, GRO, laudo de insalubridade e LTCAT com enfoque nos agentes químicos.',
        refund: '30 dias de garantia com reembolso integral',
        value: price,
        method: 'charge',
        iuguData: {
          description: 'Plano Anual',
          quantity: 1,
          price_cents: price.toString().replaceAll('.', '')
        },
        monthOptions: [
          { value: 1, text: '1x sem juros' },
          { value: 2, text: '2x sem juros' },
          { value: 3, text: '3x sem juros' },
          { value: 4, text: '4x com juros' },
          { value: 5, text: '5x com juros' },
          { value: 6, text: '6x com juros' },
          { value: 7, text: '7x com juros' },
          { value: 8, text: '8x com juros' },
          { value: 9, text: '9x com juros' },
          { value: 10, text: '10x com juros' },
          { value: 11, text: '11x com juros' },
          { value: 12, text: '12x com juros' }
        ]
      })
    },
    async next() {
      this.$emit('next-step')
    },

    getError(value) {
      if (value.$errors.length > 0) {
        return value.$errors[0].$message
      }
      return ''
    }
  },



  created() {
    try {
    } catch (e) {

    }
  }
}
</script>

<style lang='scss' scoped>

.loading {
  position: absolute;
  margin-left: -30px;
  margin-top: 12px;
  width: 1.5rem;
  height: 1.5rem;
}

.btn-radio {
  display: flex;
  flex-direction: row;

  .form-radio {
    padding: 5px;
    cursor: pointer !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .hits_value {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #04A8F3;
    }

    .hits_description {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: #262626;
    }
  }
}

.hits_select {
  border: 1px solid #04A8F3;
  padding: 5px;
  border-radius: 5px
}

.hits_no_select {
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 5px
}

.billing-data {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title_options {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #022245;
  }

  label {
    font-size: 18px;
    font-weight: 700;
  }

  .use-account-data {
    span {
      font-size: 18px;
    }

    .buttons {
      display: flex;
      gap: 8px;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h2 {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }

    .group {
      display: grid;
      grid-template-columns:  60% 38%;
      gap: 2%;

      .color_text {
        color: #04A8F3;
      }
    }
  }
}

.numberAccesses {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #022245;
}
</style>
