<template>
  <div class="not-logged">
    <div class="container">
      <h3>Para realizar a assinatura na plataforma HO Fácil Web você deverá estar logado com uma conta já existente.<br/> O que deseja fazer?</h3>
      <p>Caso você já possua uma conta, clique em Quero fazer o login e acesse a sua conta para poder prosseguir com a assinatura.<br/>
      Agora se você ainda não possuir uma conta cadastrada no HO Fácil Web, basta clicar em Quero me cadastrar para poder prosseguir com a assinatura.</p>
      <base-button success block @click="toLogin">
        Quero fazer login
      </base-button>
      <span>ou</span>
      <base-button block @click="toRegister">
        Quero me cadastrar
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotLogged',
  props: {
    plan: { type: Object, required: true }
  },
  computed: {
    userProfile() {
      return this.$store.getters['user/userProfile']
    },
  },
  methods: {
    async toRegister(){
      this.plan.checkout = true
      await this.$router.push({ name: 'Signup', query: this.plan })
    },
    async toLogin(){
      await this.$router.push({ name: 'Signin', query: this.plan })
    }
  }
}
</script>

<style lang="scss" scoped>
.not-logged {
  position: absolute;
  top: 0;

  width: 100vw;
  height: 170vh;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px;

  z-index: 100;

  background: #0000004b;

  .container {
    width: 100%;
    max-width: 600px;
    height: fit-content;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 16px;

    padding: 32px;

    background: var(--white);

    h3 {
      font-size: 24px;
      font-weight: 700;

      text-align: center;
    }
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
