<template>
  <div class="billing-data">
    <p>Os dados informados aqui serão utilizados para o pagamento do plano e emissão de nota fiscal eletrônica</p>
    <div class='btn-radio'>
      <b-form-radio-group stacked :key="index" v-for="(item, index) in typeOptions" v-model="v$.billingData.type.$model">
        <b-form-radio class='form-radio'  @change='clearFieldsSwitchBetweenLegalEntity' :value="item.key">{{item.value}}</b-form-radio>
      </b-form-radio-group>
    </div>
    <base-input-text
      v-model="v$.billingData.name.$model"
      type="text"
      label="Nome do usuário"
      placeholder="Digite o nome da pessoa que usará o sistema"
      block
      v-if="billingData.type === 'person'"
      :error="getError(v$.billingData.name)"
    />

    <div v-if="billingData.type === 'person'" class="d-flex justify-content-between w-100">
      <div class="w-50 mr-3">
        <base-input-text
          v-model="v$.billingData.document.$model"
          type="text"
          label="CPF"
          placeholder="Digite o CPF"
          v-mask="['###.###.###-##']"
          :error="getError(v$.billingData.document)"
        />
      </div>

      <div class="w-50">
        <base-input-text
          v-model="v$.billingData.birthDate.$model"
          type="date"
          label="Data de Nascimento"
          placeholder="Digite a data de Nascimento"
          block
          :error="getError(v$.billingData.birthDate)"
        />
      </div>
    </div>

    <base-input-text
      v-model="v$.billingData.companyName.$model"
      type="text"
      label="Razão Social"
      placeholder="Digite a razão social da empresa"
      block
      :error="getError(v$.billingData.companyName)"
      v-if="billingData.type === 'company'"
    />

    <div v-if="billingData.type === 'company'">
      <base-input-text
        v-model="v$.billingData.cnpj.$model"
        type="text"
        label="CNPJ"
        placeholder="Digite o CNPJ"
        v-mask="['##.###.###/####-##']"
        :error="getError(v$.billingData.cnpj)"
      />
    </div>

    <base-input-text
      v-model="v$.billingData.stateRegister.$model"
      type="text"
      label="Inscrição estadual (se houver)"
      placeholder="Digite a inscrição estadual"
      block
      :error="getError(v$.billingData.stateRegister)"
      v-if="billingData.type === 'company'"
    />

    <base-input-text
      v-model="v$.billingData.cityRegister.$model"
      type="text"
      label="Inscrição municipal (se houver)"
      placeholder="Digite a inscrição municipal"
      block
      :error="getError(v$.billingData.cityRegister)"
      v-if="billingData.type === 'company'"
    />

    <div class="w-100">
      <label>Telefone (WhatsApp)</label>

      <div class="d-flex">
        <b-form-input
          id="input-phone"
          v-model="v$.billingData.phone.$model"
          type="text"
          class='input-cpf'
          style="height: 48px;"
          placeholder="(11) 99245-8774"
          aria-invalid
          :state='phoneIsValid'
          @keyup="validatePhone($event)"
          v-mask="['(##) #####-####']">
        </b-form-input>
      </div>
      <b-form-invalid-feedback :state='phoneIsValid'>
        {{getError(v$.billingData.phone)}}
      </b-form-invalid-feedback>
    </div>

    <div class="address">
      <h2>Endereço</h2>
      <div>
        <label>CEP</label>
        <div class="d-flex">
          <b-form-input
            id="input-cep"
            v-model="v$.billingData.address.cep.$model"
            type="text"
            class='form-control'
            style="height: 48px;"
            placeholder="Digite o cep"
            @keyup="buscarCep($event)"
            :state="cepValido"
            aria-invalid
            v-mask="'##.###-###'">
          </b-form-input>
          <div>
            <b-spinner class='loading' v-if='checkingIfCEPExists' variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>
        <b-form-invalid-feedback :state="cepValido">
          {{getError(v$.billingData.address.cep)}}
        </b-form-invalid-feedback>
      </div>

      <div class="group">
        <base-input-text
          v-model="v$.billingData.address.street.$model"
          type="text"
          :disabled="!cepValido"
          label="Rua"
          placeholder="Digite o nome da rua ou avenida"
          block
          :error="getError(v$.billingData.address.street)"
        />
        <base-input-text
          v-model="v$.billingData.address.number.$model"
          type="number"
          label="Número"
          :disabled="!cepValido"
          placeholder="123"
          block
          :error="getError(v$.billingData.address.number)"
        />
      </div>

      <div class="group">
        <base-input-text
          v-model="v$.billingData.address.neighborhood.$model"
          type="text"
          label="Bairro"
          :disabled="!cepValido"
          placeholder="Digite o nome do bairro"
          block
          :error="getError(v$.billingData.address.neighborhood)"
        />
        <base-input-text
          v-model="v$.billingData.address.complement.$model"
          type="text"
          :disabled="!cepValido"
          label="Complemento (opcional)"
          placeholder="Digite o complemento"
          block
          :error="getError(v$.billingData.address.complement)"
        />
      </div>

      <div class="group">
        <base-input-text
          v-model="v$.billingData.address.city.$model"
          type="text"
          label="Cidade"
          placeholder="Digite o nome da cidade"
          block
          :error="getError(v$.billingData.address.city)"
        />
        <base-input-select
          v-model="v$.billingData.address.state.$model"
          label="Estado"
          placeholder="Selecione um estado"
          :options="stateOptions"
          :error="getError(v$.billingData.address.state)"
          _key="key"
          _value="value"
        />
      </div>
    </div>

    <div class='mt-3'>
      <b-alert
        show
        variant='danger'
        :class='{ hidden: !errors }'
      >
        {{ errors }}
      </b-alert>
    </div>

    <div class="buttons d-flex">
      <base-button
        class='w-50 mr-2'
        block
        dark
        @click="$emit('back-step')"
      >
        Voltar
      </base-button>

      <b-overlay
        :show="loading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block w-50"
      >
        <base-button
          block
          :disabled="disableButtonToNotBuyOutOfTimePlan"
          @click="next()"
        >
          Próximo
        </base-button>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@/utils/validators'
import { mask } from 'vue-the-mask'
import { mapActions, mapGetters } from 'vuex'
import { helpers} from '@vuelidate/validators'
import { CPF, CNPJ } from '../../../../utils/ValidateCPFCNPJ'
import { phoneValidator } from '../../../../utils/phoneValidator'
import { verifyCNPJExists, verifyCPFExists } from '../../../../services/Search_CPF_CNPJ_CEP'
import moment from 'moment/moment'

function validCNPJ () {
  return this.billingData.type === 'person' || this.CNPJIsValid
}
function validCEP () {
  return this.cepValido
}
function returnIfThePhoneIsCalid () {
  return this.phoneIsValid
}
export default {
  name: 'BillingData',
  setup () {
    return { v$: useVuelidate() }
  },
  props: {
    namePlan: { type: String},
  },
  data(){
    return {
      loading: false,
      disableButtonToNotBuyOutOfTimePlan: true,
      plans: {},
      cepValido: null,
      CNPJIsValid: null,
      cpfIsValid: null,
      phoneIsValid: null,
      birthDateIsValid: null,
      checkingIfCPFExists: false,
      checkingIfCNPJExists: false,
      checkingIfCEPExists: false,
      birthDate: '',
      document: '',
      plan: {},
      typeOptions: [
        { key: 'person', value: 'Pessoa Física'},
        { key: 'company', value: 'Pessoa Jurídica'},
      ],
      stateOptions: [
        { key: "Acre" , value: "Acre (AC)" },
        { key: "Alagoas" , value: "Alagoas (AL)" },
        { key: "Amapá" , value: "Amapá (AP)" },
        { key: "Amazonas" , value: "Amazonas (AM)" },
        { key: "Bahia" , value: "Bahia (BA)" },
        { key: "Ceará" , value: "Ceará (CE)" },
        { key: "Distrito Federal" , value: "Distrito Federal (DF)" },
        { key: "Espírito Santo" , value: "Espírito Santo (ES)" },
        { key: "Goiás" , value: "Goiás (GO)" },
        { key: "Maranhão" , value: "Maranhão (MA)" },
        { key: "Mato Grosso " , value: "Mato Grosso (MT)" },
        { key: "Mato Grosso do Sul" , value: "Mato Grosso do Sul (MS)" },
        { key: "Minas Gerais" , value: "Minas Gerais (MG)" },
        { key: "Pará" , value: "Pará (PA)" },
        { key: "Paraíba" , value: "Paraíba (PB)" },
        { key: "Paraná" , value: "Paraná (PR)" },
        { key: "Pernambuco" , value: "Pernambuco (PE)" },
        { key: "Piauí" , value: "Piauí (PI)" },
        { key: "Rio de Janeiro" , value: "Rio de Janeiro (RJ)" },
        { key: "Rio Grande do Norte" , value: "Rio Grande do Norte (RN)" },
        { key: "Rio Grande do Sul" , value: "Rio Grande do Sul (RS)" },
        { key: "Rondônia" , value: "Rondônia (RO)" },
        { key: "Roraima" , value: "Roraima (RR)" },
        { key: "Santa Catarina" , value: "Santa Catarina (SC)" },
        { key: "São Paulo" , value: "São Paulo (SP)" },
        { key: "Sergipe" , value: "Sergipe (SE)" },
        { key: "Tocantins" , value: "Tocantins (TO)" },
      ],
    }
  },
  directives: { mask },
  computed: {
    ...mapGetters('user', {
      user: 'current',
    }),
    ...mapGetters('checkout2', {
      billingData: 'getBillingData',
      getSteps: 'getSteps',
    }),
    ...mapGetters('plans', {
      getPlans: 'getPlans'
    }),
    errors() {
      return this.$store.getters['access/error']
    }
  },
  validations () {
    return {
      billingData: {
        type: {},
        name: { requiredIf: requiredIf(this.billingData.type === 'person') },
        birthDate: { requiredIf: requiredIf(this.billingData.type === 'person') },
        document: { requiredIf: requiredIf(this.billingData.type === 'person') },
        companyName: { requiredIf: requiredIf(this.billingData.type === 'company') },
        phone: { returnIfThePhoneIsCalid: helpers.withMessage('Telefone inválido', returnIfThePhoneIsCalid ) },
        cnpj: { requiredIf: requiredIf(this.billingData.type === 'company') },
        stateRegister: {},
        cityRegister: {},
        address: {
          street: { required },
          number: { required },
          complement: {},
          neighborhood: { required },
          cep: { validCEP: helpers.withMessage('CEP inválido, por favor digite um cep valido para prosseguir.', validCEP ) },
          city: { required },
          state: { required },
        },
      }
    }
  },
  methods: {
    ...mapActions('checkout2', {
      handleSetBillingData: 'handleSetBillingData',
    }),
    ...mapActions('userActionLogs', {
      createUserActionLogs: 'createUserActionLogs',
    }),
    async next() {
      this.loading = true;
      await this.$store.dispatch('access/updateError', ``)
      let validate = await this.v$.$validate()
      const isValidPhone = this.validatePhone();
      if(!isValidPhone){
        await this.$store.dispatch('access/updateError', `Telefone inválido`)
        this.loading = false;
        return
      }
      /*const cepIsvalid = await this.buscarCep();
      if(!cepIsvalid){
        await this.$store.dispatch('access/updateError', `CEP inválido, por favor digite um cep valido para prosseguir.`)
        this.loading = false;
        return
      }*/

      validate = await this.v$.$validate()
      if(!validate){
        await this.$store.dispatch('access/updateError', `Preencha todos os campos corretamente`)
        this.loading = false;
        return
      }

      if (this.billingData.type === 'person'){
        const isDateOfBirthValid = this.validateInformedDateOfBirth()
        if(!isDateOfBirthValid){
          await this.$store.dispatch('access/updateError', `Data de nascimento inválida!`)
          this.loading = false;
          return
        }

        const isValid = await this.buscarCPF();
        if(!isValid){
          await this.$store.dispatch('access/updateError', `CPF inválido`)
          this.loading = false;
          return
        }
      }

      if (this.billingData.type === 'company'){
        const isCNPJValid = await this.buscarCNPJ();
        if(!isCNPJValid){
          await this.$store.dispatch('access/updateError', `CNPJ inválido`)
          this.loading = false;
          return
        }
      }

      if (this.user && this.user.role !== 'admin') {
        const log = {
          action: 'PREENCHEU_DADOS_FATURAMENTO'
        }
        await this.createUserActionLogs(log)
      }

      await this.$store.dispatch('access/updateError', ``)
      this.loading = false;
      this.$emit('next-step')
    },

    clearFieldsSwitchBetweenLegalEntity(){
      try {
        this.billingData.companyName = ''
        this.billingData.cnpj = ''
        this.billingData.name = ''
        this.billingData.document = ''
        this.billingData.stateRegister = ''
        this.billingData.cityRegister = ''
        this.cpfIsValid = null
        this.CNPJIsValid = null
      } catch (e) {
      }
    },

    async buscarCep(){
      try{
        const cepComApenasNumeros = this.billingData.address.cep.replace('.', '').replace('-', '')
        if(cepComApenasNumeros) {
          this.cepValido = null;
          if (cepComApenasNumeros.length > 7) {
            this.checkingIfCEPExists = true;
            const { data: response } = await new Promise((resolve, reject) => {
              this.$api.get(`/service/cep/${cepComApenasNumeros}`)
                .then((data) => {
                  return resolve(data)
                })
                .catch((error) => {
                  return resolve(error.response)
                })
            })
            this.checkingIfCEPExists = false;
            const { return: ok, message, result } = response
            this.cepValido = ok === "OK" && !message
            this.billingData.address.cep = this.billingData.address.cep;
            this.billingData.address.street = result && result.logradouro ? result.logradouro : ''
            this.billingData.address.neighborhood = result && result.bairro ? result.bairro : ''
            this.billingData.address.city = result && result.localidade? result.localidade : ''
            this.billingData.address.complement = result && result.complemento? result.complemento : ''
            this.billingData.address.state = result && result.uf ? this.stateOptions.find((x) => x.value.indexOf(result.uf) > 0).key : ''
            return this.cepValido
          }
          this.cepValido = false;
          return false;
        }
        this.cepValido = false;
        return  false;
      } catch (e) {
      }
    },

    async buscarCNPJ(){
      const cnpjComApenasNumeros = this.billingData.cnpj.replace(/[^\d]+/g, '')
      if(cnpjComApenasNumeros) {
        this.CNPJIsValid = null
        if (CNPJ(cnpjComApenasNumeros)) {
          this.checkingIfCNPJExists = true;
          const { data: response } = await verifyCNPJExists(cnpjComApenasNumeros)
          this.checkingIfCNPJExists = false;
          const { cnpjExists } = response
          this.CNPJIsValid = cnpjExists
          return this.CNPJIsValid
        }
        this.CNPJIsValid = false
        return false
      }
      return false
    },

    async buscarCPF(){
      const cpfComApenasNumeros = this.billingData.document.replace(/[^\d]+/g, '')
      if(cpfComApenasNumeros && this.billingData.birthDate) {
        this.cpfIsValid = null;
        if(CPF(cpfComApenasNumeros)) {
          this.checkingIfCPFExists = true;
          //const { data: response } = await verifyCPFExists(cpfComApenasNumeros, this.billingData.birthDate)
          this.checkingIfCPFExists = false;
          //const { cpfExists } = response
          this.cpfIsValid = true
          return this.cpfIsValid
        }
        this.cpfIsValid = false;
        return false
      }
      return false;
    },

    validatePhone(){
      try {
        const phone = this.billingData.phone.replace(/[^\d]/g, '')
        this.phoneIsValid = phoneValidator(phone) && phone.length >= 10
        return this.phoneIsValid
      } catch (e) {
      }
    },

    validateInformedDateOfBirth() {
      const endDate = moment(new Date()).add(-18, 'year').toDate()
      const initialDate = moment(new Date(1900, 0, 1))
      const birthDate = moment(new Date(this.billingData.birthDate))
      return birthDate.isAfter(initialDate) && birthDate.isBefore(endDate) && this.billingData.birthDate
    },

    getError(value){
      if(value.$errors.length > 0){
        return value.$errors[0].$message
      }
      return ''
    },
    createHtmlInputCPF(){
      document.getElementById('input-cpf').addEventListener('keyup', async (e) => {
        this.billingData.document = e.target.value
        await this.buscarCPF()
      })
    }
  },
  async mounted() {
    if(this.namePlan !== 'PROMO'){
      this.disableButtonToNotBuyOutOfTimePlan = false;
    }
    this.plans = this.getPlans
    this.plan = this.plans[this.namePlan]
    const today = new Date(this.plan.today)
    const validFrom = new Date(this.plan.validFrom)
    const validUntil = new Date(this.plan.validUntil)
    if (today.getTime() >= validFrom.getTime() && today.getTime() <= validUntil.getTime()){
      this.disableButtonToNotBuyOutOfTimePlan = false;
    }
  },
  async created() {
    try {
      await this.$store.dispatch('access/updateError', ``)
      await this.buscarCep()
      if (this.user && this.user.username) {
        this.billingData.email = this.user.username
      }
      const { utm_source, utm_medium, utm_campaign, utm_id, utm_term, utm_content } = this.$route.query
      this.billingData.utm_source = utm_source ? utm_source : null
      this.billingData.utm_medium = utm_medium ? utm_medium : null
      this.billingData.utm_campaign = utm_campaign ? utm_campaign : null
      this.billingData.utm_id = utm_id ? utm_id : null
      this.billingData.utm_term = utm_term ? utm_term : null
      this.billingData.utm_content = utm_content ? utm_content : null
    } catch (e) {

    }
  }
}
</script>

<style lang="scss" scoped>

.loading{
  position: absolute;
  margin-left: -30px;
  margin-top: 12px;
  width: 1.5rem;
  height: 1.5rem;
}
.btn-radio{
  display: flex;
  flex-direction: row;
  .form-radio{
    margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer!important;
  }
}
.billing-data {
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    font-size: 18px;
    font-weight: 700;
  }
  .use-account-data {
    span {
      font-size: 18px;
    }
    .buttons {
      display: flex;
      gap: 8px;
    }
  }

  .address {
    display: flex;
    flex-direction: column;
    gap: 4px;
    h2 {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
    }
    .group {
      display: grid;
      grid-template-columns:  60% 38%;
      gap: 2%;
    }
  }
}
</style>
